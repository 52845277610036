export const environment = {
  production: true,
  baseUrl: null,
  demo: false,
  ga: '',
  plausible: null,
  titlePrefix: 'MyCatholicWill Admin',
  minutesBeforeTimeOut: 15,
  homeUrl: '',
  features: {
    timeEntriesActualTime: true,
  },
  sentryDsn: null as string | null,
  environmentName: 'default',
};
